import { CardMedia } from '@material-ui/core';
import styled from 'styled-components';

const CardImage = styled(CardMedia)`
  height: 500px;

  @media (max-width: 959px) {
    height: 400px;
  }

  @media (max-width: 599px) {
    height: 200px;
  }
`;

export default CardImage;
