import React from 'react';
import styled from 'styled-components';
import { Avatar as MUAvatar, AvatarProps } from '@material-ui/core';

type Props = AvatarProps & {
  width?: number;
  height?: number;
  auto?: boolean;
  name?: string | null;
};

const toLetters = (name?: string | null): string | undefined => name?.substr(0, 1);

const StyledAvatar = styled(MUAvatar)<Props>`
  width: ${({ width, auto }): string => `${auto ? '100%' : `${width || 30}px`}`};
  height: ${({ height, auto }): string => `${auto ? 'initial' : `${height || 30}px`}`};
`;

const Avatar = ({ name, ...props }: Props): JSX.Element => (
  <StyledAvatar {...props}>{toLetters(name)}</StyledAvatar>
);
export default Avatar;
