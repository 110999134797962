import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import {
  Typography,
  Container,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { TeamPageQuery } from '../../types/graphql-types';
import CardImage from '../components/image/card-image';
import RichContent from '../components/text/rich-content';
import Link from '../components/Link';
import Avatar from '../components/Avatar/Avatar';

export interface TeamPageProps {
  data: TeamPageQuery;
}

const TeamPage = ({ data }: TeamPageProps): JSX.Element => {
  const { contentfulTeam } = data;
  const image = contentfulTeam?.heroImage?.gatsbyImageData?.images?.fallback?.src;

  return (
    <>
      <SEO title={contentfulTeam?.title || ''} />
      <Box mb={5} mt={5}>
        <Container>
          <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
            {contentfulTeam?.title}
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph>
            {contentfulTeam?.description?.description}
          </Typography>
          <CardImage image={image} title="Image title" />
          <RichContent
            dangerouslySetInnerHTML={{
              __html: contentfulTeam?.body?.childMarkdownRemark?.html || '',
            }}
          />
        </Container>
      </Box>
      <Container>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {contentfulTeam?.teamMembers?.map((member, index) => (
                <TableRow key={member?.email || index}>
                  <TableCell component="th" scope="row">
                    <Avatar
                      width={60}
                      height={60}
                      aria-label="Profile"
                      alt={member?.name || undefined}
                      src={member?.image?.gatsbyImageData?.src || undefined}
                    ></Avatar>
                  </TableCell>
                  <TableCell align="left">
                    <Link href={`/team/${member?.slug}`}>{member?.name}</Link>
                  </TableCell>
                  <TableCell align="left">{member?.title}</TableCell>
                  <TableCell align="left">{member?.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default TeamPage;

export const pageQuery = graphql`
  query TeamPage {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTeam {
      title
      heroImage {
        gatsbyImageData(width: 1232, height: 1080, quality: 90)
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      teamMembers {
        email
        name
        title
        slug
        image {
          gatsbyImageData(width: 60, height: 60, quality: 90)
        }
      }
    }
  }
`;
